import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let cookie = this.element.dataset.cookie
    let key = this.element.dataset.key
    let delay = parseInt(this.element.dataset.delay) || 0

    if (localStorage.getItem(cookie) != key) {
      setTimeout(() => {
        $.fancybox.open(this.element, {
          maxWidth  : 800,
          maxHeight : 600,
        })

        localStorage.setItem(cookie, key)
      }, delay)
    }
  }
}
