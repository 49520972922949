import { Controller} from "stimulus"

// Create an HTML element on the page.
// This is a basic starter example: 
//
// ```
// <form data-controller="password-protection" data-password-value="testing" data-destination-value="https://www.google.com" data-action="submit->password-protection#submit">
//   <input type="text" name="password" data-target="password-protection.passwordInput" />
//   <input type="submit">
// </form>
// ```
//
// Change the data-password-value to whatever you want the password to be.
// Change the data-destination-value to the full URL of the place you would like the 
// user to be redirected after successfully entering the password.

export default class extends Controller {
  static targets = [ "passwordInput" ]

  submit(e) {
    e.preventDefault()
    if(this.passwordInputTarget.value == this.element.dataset.passwordValue){
      window.location.href = this.element.dataset.destinationValue
    }
  }
}