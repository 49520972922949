import { Controller } from "stimulus"

const isRegularClick = (event) => {
  return !(
    (event.target && event.target.isContentEditable) ||
    event.defaultPrevented ||
    event.which > 1 ||
    event.altKey ||
    event.ctrlKey ||
    event.metaKey ||
    event.shiftKey
  )
}

const toggleClass = (element, className, state) => {
  if (state && !element.classList.contains(className)) {
    element.classList.add(className)
  } else if (!state && element.classList.contains(className)) {
    element.classList.remove(className)
  }
}

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handle.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('click')
  }

  handle(e) {
    if (!isRegularClick(e)) {
      return
    }
    
    let targetSelector = this.data.get('target')
    let target = document.querySelector(targetSelector)

    if (!target) {
      return
    }

    let className = this.data.get('class')
    let state = !target.classList.contains(className)

    toggleClass(target, className, state)
  }
}