import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radioInput", "numberInput"]

  connect() {
    // We listen here through jQuery because bootstrap triggers jQuery events for these change events
    $(this.element).on("change", (e) => { this.handleChange(e) })
  }

  handleChange(e) {
    // Bail out if we changed something other than a radio
    if (e.target.type !== "radio") {
      return
    }

    if (e.target == this.radioInputTarget) {
      this.numberInputTarget.disabled = false
    } else {
      this.numberInputTarget.disabled = true
    }
  }
}