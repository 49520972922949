import { Controller } from "stimulus"
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ["card", "formErrors", "fieldset", "success"]

  connect() {
    $(this.element).parents(".modal").on("shown.bs.modal", () => { 
      this._initializeStripe()
    })

    $(this.element).on("ajax:send", () => {
      this.fieldsetTarget.disabled = true
    })
    $(this.element).on("ajax:success", (e, data) => {
      this._submitStripe(data)
    })
    $(this.element).on("ajax:error", (e, xhr) => {
      this._handleErrors(xhr)
      this.fieldsetTarget.disabled = false
    })

    this.successTarget.style.display = 'none'
  }

  _initializeStripe() {
    loadStripe(this.data.get("stripeKey")).then((stripe) => { 
      this.stripe = stripe
      this.elements = stripe.elements()
      this.card = this.elements.create("card")
      this.card.mount(this.cardTarget)
    })
  }

  _submitStripe(data) {
    let clientSecret = data.client_secret
    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: this.card
      }
    }).then((result) => {
      if (result.error) {
        this._showErrors([result.error.message])
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          this.element.reset()
          this.card.clear()
        }
      }
    }).finally(() => {
      this.fieldsetTarget.disabled = false
      this.successTarget.style.display = ''
    })
  }

  _handleErrors(xhr) {
    if (xhr.status == 422) {
      let errors = xhr.responseJSON.errors
      this._showErrors(errors)
    } else {
      this._showErrors([xhr.responseText])
    }
  }

  _showErrors(errors) {
    let html = `
      <ul>
        ${errors.map((e) => `<li>${e}</li>`).join("")}
      </ul>
    `
    this.formErrorsTarget.innerHTML = html
  }
}