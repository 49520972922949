import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["conversionText"]

  connect() {
    this.hideConversionText()
  }

  updateConversion(e) {
    const enteredAmount = parseFloat(e.target.value)
    const { fromCurrency, toCurrency, conversionRate } = this.element.dataset

    const formattedUnconvertedAmount = this.formatCurrency(fromCurrency, enteredAmount)
    const convertedAmount = Math.round((enteredAmount / conversionRate) * 100) / 100
    const formattedConvertedAmount = this.formatCurrency(toCurrency, convertedAmount)
    
    this.conversionTextTarget.innerHTML = `(${formattedUnconvertedAmount} ${fromCurrency} ≈ ${formattedConvertedAmount} ${toCurrency})`
    
    const shouldDisplayConversionText = fromCurrency !== toCurrency && enteredAmount > 0
    if (shouldDisplayConversionText) {
      this.showConversionText()
    } else {
      this.hideConversionText()
    }
  }

  formatCurrency(currency, amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount)
  }

  hideConversionText() {
    this.conversionTextTarget.style.display = 'none'
  }

  showConversionText() {
    this.conversionTextTarget.style.display = ''
  }
}