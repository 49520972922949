import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let showAfter = parseInt(this.data.get('duration'))
    this.timeout = setTimeout(() => {
      this.element.classList.add(this.data.get("class"))
    }, showAfter)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}